<html>
  <body  style="background-color: rgb(236, 252, 244);">
<span class="fa fa-spinner spinner" 
    *ngIf="loading"></span>

  <div align="center" >
    <label class="navbar-brand"><img src="./assets/images/dragon.png" width="10%">{{pageTitle}}<img src="./assets/images/dragon.png" width="10%"></label>
  </div>
  <nav class="navbar navbar-expand navbar-light bg-light">  
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active">Home</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/games']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Games</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/about']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About Us</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/privacy']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Privacy Policy</a>
    </li>
   <!-- <li class="nav-item">
      <a class="nav-link" [routerLink]="['/products', 0, 'edit']" routerLinkActive="active">Add Product</a>
    </li>-->
  </ul> 
</nav>
<div class="container">
  <div class="row">
    <!-- <div class="col-md-10"
      </div> [@slideInAnimation]="o.isActivated ? o.activatedRoute: ''"> -->
        <router-outlet #o="outlet"></router-outlet>
    <!-- </div>
  <div class="col-md-2">
    <router-outlet name="popup"></router-outlet>-->
  </div>
  <div> © Copyright Sleepy Dragon Games Inc. </div>
</div>

</body>
</html>