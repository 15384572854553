import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  page = {
    title: 'Home',
    subtitle: 'Welcome',
    content: 'Some home content',
    image: '../../assets/dragon.png'

  };
  
  constructor() { }

  ngOnInit(): void {
  }

}
