import { Component, OnInit } from '@angular/core';
import { IGame } from './game';
import { GameService } from './game.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.css']
})
export class GamesComponent implements OnInit {

  imageWidth: number = 50;
  imageMargin: number = 2;
  
  errorMessage: string;

  allGames: IGame[];
  completedGames: IGame[] = [];
  wipGames: IGame[];
  queueGames: IGame[];

  constructor(private gameService: GameService) 
  {
  
  }

  ngOnInit(): void 
  {
    this.gameService.getGames().subscribe(
      {
        next: games => 
        {
          this.allGames = games;
          this.completedGames = games;
          console.log("all:" + this.allGames[0]);
          this.completedGames = this.performFilterByStatus("Completed");
          console.log(this.completedGames);
          this.wipGames= this.performFilterByStatus("WIP");
          this.queueGames = this.performFilterByStatus("Queue");

        },
        error: err => this.errorMessage = err
      }
    );
  }

  performFilterByStatus(filterBy: string): IGame[]
  {
    //filterBy = filterBy.toLocaleLowerCase();
    return this.allGames.filter((game: IGame) =>
    game.status.indexOf(filterBy) !== -1);    
  }

  showDetails():void
  {
     
  }
}
