import { Component, OnInit } from '@angular/core';
import { IGame } from './game';
import { GameService } from './game.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-game-details',
  templateUrl: './game-details.component.html',
  styleUrls: ['./game-details.component.css']
})
export class GameDetailsComponent implements OnInit {
  pageTitle = 'Game Details';
  game: IGame;
  errorMessage: string;

  constructor(private gameService: GameService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void 
  {
    const id = +this.route.snapshot.paramMap.get('id');
    if (id)
    { 
      console.log("get game " + id.toString());
      this.getGame(id);
    }
  }

  getGame(id: number)
  {
    this.gameService.getGame(id).subscribe(
      {
        next: game => this.onGameRetrieved(game),
        error: err=> this.errorMessage = err
      }
    );
  }

  onGameRetrieved(retrievedGame: IGame): void
  {
    console.log("on retrieve");
    this.game = retrievedGame;
  }

  onBack(): void
  {
    this.router.navigate(['/games']);
  }
}
