import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { PrivacyComponent } from './privacy/privacy.components';
import { LandingComponent } from './landing/landing.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent},
  {
    path: 'games', 
    loadChildren: () =>
      import('./games/games.module').then(m=>m.GamesModule)
  },
  {
    path: 'about', component : AboutComponent
  },
  {
    path: 'privacy', component: PrivacyComponent
  },
  { path: 'newsletter', component: LandingComponent },
  { path: '', redirectTo: 'home', pathMatch:'full'  },  
  { path: '**', redirectTo: 'home', pathMatch:'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
