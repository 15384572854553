import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { GamesModule } from './games/games.module';
import { AboutComponent } from './about/about.component';
import { PrivacyComponent } from './privacy/privacy.components';
import { HttpClientModule } from '@angular/common/http';
import { LandingComponent } from './landing/landing.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PostService } from './post.service';
import { PopupComponent } from './popup/popup.component';

import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule } from '@angular/material/dialog';




@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    PrivacyComponent,
    LandingComponent,
    PopupComponent, 
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,    
    GamesModule,
    HttpClientModule, 
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatDialogModule    
  ],
  providers: [PostService],
  bootstrap: [AppComponent]
})
export class AppModule { }
