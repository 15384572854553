import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { PostService } from '../post.service';
import { Router } from "@angular/router"
import { PopupComponent } from '../popup/popup.component';
import { MatDialog, MatDialogRef } from  '@angular/material/dialog';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  
  postService: PostService;
  router: Router;
  userEmail = new FormGroup({
    email: new FormControl('',[
      Validators.required, 
      Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$")])    
  });

  get primEmail()
  {
    return this.userEmail.get('email');
  }

  constructor(private http: HttpClient, private fb: FormBuilder, postService: PostService, router: Router, private  dialog:  MatDialog) 
  {
    
    this.postService = postService;
    this.router = router;
  }

  submit() {
    console.log('Submitt this: ', this.userEmail.value);
    //let functionUrl = 'https://collectemailaddress.azurewebsites.net/api/StoreEmail?code=vKLgNEkZDjPYUPNjOpDD1ZSieGBiBVrPvHe9hhmk2LVPAzFu65AehA=='
    
    this.postService.postAddress(this.userEmail.value["email"]);
    this.dialog.open(PopupComponent, {data: { message: "Hello"}})
    this.router.navigate(['/games']);
  }

  ngOnInit(): void {
  }

}
