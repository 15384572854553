import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  page = {
    title: 'Privacy Policy',
    subtitle: 'Welcome',
    content: 'Our privacy statement',
    image: '../../assets/dragon.png'

  };
  
  constructor() { }

  ngOnInit(): void {
  }

}
