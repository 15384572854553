import { Injectable } from '@angular/core';
//import { Observable, Subject } from 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(public http: HttpClient) 
  { 

  }

  public postAddress(email: string)
  {
   let functionUrl = 'https://collectemailaddress.azurewebsites.net/api/StoreEmail?code=vKLgNEkZDjPYUPNjOpDD1ZSieGBiBVrPvHe9hhmk2LVPAzFu65AehA=='
   //let functionUrl = 'http://localhost:7071/api/StoreEmail'
    let headers = new Headers();
    headers.append('Content-Type','application/json');

    let input = `{ "fromEmail":"${email}" }`;
    
    console.log('Sending this: ', input, functionUrl);
    this.http.post(functionUrl, input ).subscribe(data => {
      console.log(data);
    });    
  }
}
