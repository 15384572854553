import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

import { IGame } from './game';

@Injectable({
  providedIn: 'root'
})
export class GameService {
  private gameUrl = 'api/games/games.json';

  constructor(private http: HttpClient) { }

  getGames(): Observable<IGame[]> 
  {
    return this.http.get<IGame[]>(this.gameUrl)
    .pipe(
      tap(),
      catchError(this.handleError)

    );
  }

  getGame(id: number): Observable<IGame>| undefined
  {
    return this.getGames().pipe(
      map((games: IGame[]) => games.find(g => g.gameId === id))
      );
  }

  private handleError(err: HttpErrorResponse)
  {
    let errorMessage ='';

    if (err.error instanceof ErrorEvent)
    {
      errorMessage = `An error occured: ${err.error.message}`;
    }
    else
    {
      errorMessage=`Server returned code: ${err.status}, error message is: ${err.message} `;
    }

    console.log(errorMessage);
    return throwError(errorMessage)
  }
}
