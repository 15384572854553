import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GamesComponent } from './games.component';
import { RouterModule } from '@angular/router';
import { GameDetailsComponent } from './game-details.component';




@NgModule({
  declarations: 
  [
    GamesComponent,
    GameDetailsComponent,
   
  ],
  imports: [
    CommonModule,
    FormsModule,  
    RouterModule.forChild(
      [
        { path: '', component: GamesComponent},
        { path: ':id', component: GameDetailsComponent}
      ]
    )
  ]
})
export class GamesModule { }
