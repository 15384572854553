<div class='card'
     *ngIf='game'>
  <div class='card-header'>
    {{pageTitle + ': ' + game.gameName}}
  </div>

  <div class='card-body'>
    <div class='row'>
      <div class='col-md-8'>
        <div class='row'>
          <div class='col-md-4'>Name:</div>
          <div class='col-md-8'>{{game.gameName}}</div>
        </div>       
        <div class='row'>
          <div class='col-md-4'>Description:</div>
          <div class='col-md-8'>
            <div *ngFor="let desc of game.longDescription">
              <div> 
                {{desc}}
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div class='row'>
          <div class='col-md-4'>Released:</div>
          <div class='col-md-8'>{{game.releaseDate}}</div>
        </div>
        <div class='row'>
          <div class='col-md-4'>Price:</div>
          <div class='col-md-8'>{{game.price}}</div>
        </div>        
        <div *ngIf='game.imageUrls && game.imageUrls.length'>
          <div>
            <a *ngFor='let image of game.imageUrls'>
              <img [src]=image [style.width.px]='300' [style.margin.px]='2'>
            </a>
          </div>
        </div>
      </div>

      <div class='col-md-4'>
        <img class='center-block img-responsive'
             [style.width.px]='200'
             [style.margin.px]='2'
             [src]='game.icon'
             [title]='game.gameName'>
      </div>
    </div>
  </div>

  <div class='card-footer'>
    <button class='btn btn-outline-secondary'
            (click)='onBack()'
            style='width:80px'>
      <i class='fa fa-chevron-left'></i> Back
    </button>
  </div>
</div>