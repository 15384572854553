<div>    
    <label>Works in Progress</label>
    <table class='table' *ngIf='wipGames && wipGames.length'>        
        <thead>
            <tr>
                <th width='100'></th>
                <th width='200'>Game</th>
                <th>Price???</th>
                <th>Description</th>
                <th>Release???</th>
                <th>Details</th>
            </tr>
        </thead>
        <tbody *ngFor="let game of wipGames">
            <td><img [src]='game.icon' [title]='game.gameName' [style.width.px]='imageWidth' [style.margin.px]='imageMargin'></td>
            <td><a [routerLink]="['/games', game.gameId ]">{{ game.gameName }}</a></td>
            <td>{{ game.price }}</td>
            <td>{{ game.shortDescription }}</td>
            <td>{{ game.releaseDate }}</td>
            <td><button class="btn btn-primary" [routerLink]="['/games', game.gameId ]" >Details</button></td>
            
        </tbody>
    </table>
</div>

<div>   
    <label>Released Games</label> 
    <table class='table' *ngIf='completedGames && completedGames.length'>        
        <thead>
            <tr>
                <th width='100'></th>
                <th width='200'>Game</th>
                <th>Price</th>
                <th>Description</th>
                <th>Released</th>
                <th>Details</th>
            </tr>
        </thead>
        <tbody *ngFor="let game of completedGames">
            <td><img [src]='game.icon' [title]='game.gameName' [style.width.px]='imageWidth' [style.margin.px]='imageMargin'></td>
            <td><a [routerLink]="['/games', game.gameId ]">{{ game.gameName }}</a></td>
            <td>{{ game.price }}</td>
            <td>{{ game.shortDescription }}</td>
            <td>{{ game.releaseDate }}</td>
            <td><button class="btn btn-primary" [routerLink]="['/games', game.gameId ]" >Details</button></td>
            
        </tbody>
    </table>
</div>
<div>    
    <label>In the Queue</label>
    <table class='table' *ngIf='queueGames && queueGames.length'>
        <thead>            
            <th width='100'></th>
            <th width='200'>Game</th>
            <th>Description</th>
            <th>Details</th>
        </thead>
        <tbody *ngFor="let game of queueGames">
            <td><img [src]='game.icon' [title]='game.gameName' [style.width.px]='imageWidth' [style.margin.px]='imageMargin'></td>
            <td><a [routerLink]="['/games', game.gameId ]">{{ game.gameName }}</a></td>
            <td>{{ game.shortDescription }}</td>
            <td><button class="btn btn-primary" [routerLink]="['/games', game.gameId ]" >Details</button></td>
        </tbody>
    </table>
</div>
